<template>
  <div class="suppliers px-5 py-5">
    <div class="row">
      <div class="col">
        <h1>Editar Fornecedor</h1>
        <h6 class="fw-400">{{ supplier.title }}</h6>
      </div>
    </div>
    <hr>
    <FormSuppliers :_supplier="supplier" @update="update" />
    <LoadingComponent :loading="loading || !supplier.id" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import FormSuppliers from './FormSuppliers'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'ShowSuppliersPage',

  components: {
    LoadingComponent,
    FormSuppliers
  },

  data () {
    return {
      supplier: {}
    }
  },

  watch: {
    details () {
      this.supplier = {
        ...this.details
      }
    }
  },

  computed: mapState({
    details: state => state.suppliers.details,
    loading: state => state.suppliers.loading
  }),

  methods: {

    ...mapActions('suppliers', [
      'show',
      'update'
    ])

  },

  mounted () {
    this.show(this.$route.params.id)
  }

}

</script>
