import Http from '../http'

export const index = (page, search, filter, status) => {
  var url = `/orders?page=${page}`
  if (search) url = url + `&search=${search}`
  if (filter.payment) url = url + `&payment=${filter.payment}`
  if (filter.shipping) url = url + `&shipping=${filter.shipping}`
  if (filter.payment_type) url = url + `&payment_type=${filter.payment_type}`
  if (filter.status) url = url + `&status=${filter.status}`
  return Http.get(url, { headers: { Authorization: localStorage.token } })
}
export const show = (id) => Http.get(`/orders/${id}`, { headers: { Authorization: localStorage.token } })
export const update = (id, data) => Http.patch(`/orders/${id}`, { data }, { headers: { Authorization: localStorage.token } })
export const excel = (from, to) => Http.get(`/order-export-excel?from=${from}&to=${to}`, { headers: { Authorization: localStorage.token } })
