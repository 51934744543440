import { index } from '../../services/reports/reports'

export const reports = {

  namespaced: 'reports',

  state: {
    data: {
      financial: {}
    },
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, types) {
      index(types)
        .then(response => {
          commit('setData', response.data.reports)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    }

  },

  mutations: {

    setData (state, reports) {
      state.data = reports
    },

    setDetails (state, category) {
      state.details = category
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
