<template>
  <div class="login">
    <div class="row justify-content-center">
      <div class="col-md-5 pb-5 mb-5 px-5">
        <img :src="preferences.data.avatar" alt="">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 mx-auto">
        <div class="card p-4">
          <h1 class="text-center">Login</h1>
          <form @submit.prevent="login(user)">
            <div class="form-group">
              <label for="email" class="control-label text-white">E-mail:</label>
              <input v-model="user.email" type="email" class="form-control" placeholder="Digite seu email" required>
            </div>
            <div class="form-group">
              <label for="password" class="control-label text-white">Senha:</label>
              <input v-model="user.password" type="password" class="form-control" placeholder="Digite sua senha" required>
            </div>
            <button class="btn btn-success">
              Entrar
            </button>
          </form>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="auth.loading" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'LoginPage',

  components: {
    LoadingComponent
  },

  data () {
    return {
      user: {
        email: null,
        password: null
      }
    }
  },

  computed: mapState({
    preferences: state => state.preferences,
    auth: state => state.auth
  }),

  methods: {

    ...mapActions('auth', [
      'login'
    ])

  }

}

</script>
