import { login } from '../../services/auth/auth'

export const auth = {

  namespaced: 'auth',

  state: {
    data: {},
    user: {},
    loading: false,
    error: null
  },

  actions: {

    login ({ state, commit }, data) {
      commit('setLoading', true)
      login(data)
        .then(response => {
          commit('setAuth', response.data.auth)
          window.location.href = './'
          commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          commit('setError', error.response.message)
          commit('setLoading', false)
        })
    },

    logout ({ commit }) {
      commit('setLoading', true)
      localStorage.clear()
      window.location.href = './login'
    }
  },

  mutations: {

    setAuth (state, auth) {
      localStorage.token = 'Bearer ' + auth.token
      state.user = {
        name: auth.name,
        email: auth.email,
        type: auth.user_type_id
      }
      localStorage.setItem('user', JSON.stringify(state.user))
    },

    setLoading (state, status) {
      state.loading = status
    }

  },

  getters: {}

}
