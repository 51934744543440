<template>
    <div class="card mb-3">
        <div class="card-left">
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 1H3C1.89543 1 1 1.89543 1 3V15C1 16.1046 1.89543 17 3 17H21C22.1046 17 23 16.1046 23 15V3C23 1.89543 22.1046 1 21 1Z" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 7H23" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="card-right">
            <Payments :payment_status="payments"/>
            <p><b>{{ payments[0] ? payments[0].param_payment_type_id : '' | payment_type }}</b></p>
            <p>Parcelas: {{payments[0].installments}}</p>
            <p v-if="tax > 0">Taxa: {{ tax | money }}</p>
            <p>Total: {{ amount | money }}</p>
            <p v-if="order.coupon_text != null">Cupom: {{ order.coupon_text }}</p>
            <p v-if="cupom">Cupom: {{ cupom }}</p>
            <p v-if="payments[0].payment_on_delivery"><b>Pagamento na entrega</b></p>
            <p v-if="payments[0].payment_change > 0">Troco para: {{ payments[0].payment_change | money }}</p>
        </div>
    </div>
</template>

<script>
import PaymentStatusComponent from '../components/PaymentStatusComponent.vue'

export default {

  components: {
    Payments: PaymentStatusComponent
  },

  props: ['payments', 'cupom', 'amount', 'order', 'tax'],

  data () {
    return {
      payment_detail: {}
    }
  }
}
</script>

<style scoped>
    .my-card-title {
        font-size: 23px !important;
    }
</style>
