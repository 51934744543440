<template>
  <div class="integrations">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-sm-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1>Integrações</h1>
              <button class="btn btn-success my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
                Nova Integração
              </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="integration in integrations.data" :key="integration.id">
                <td class="align-middle">
                  <router-link class="body-table c-black" :to="'./integrations/' + integration.id">
                    {{ integration.id }}
                  </router-link>
                </td>
                <th class="align-middle">
                  <router-link class="body-table c-black" :to="'./integrations/' + integration.id">
                    {{ integration.integration.description }}
                  </router-link>
                </th>
                <td>
                  <button class="btn mr-2 " data-toggle="modal" @click="openUpdateModal(integration)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn" data-toggle="modal" @click="openDeleteModal(integration)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !integrations.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (integration.id ? 'Editar Integração' : 'Cadastrar Integração')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label class="form-control-label">Integração: </label>
                <input type="text" class="form-control" v-model="integration.integration.description">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Client ID: </label>
                <input type="text" class="form-control" v-model="integration.integration_id">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">access_token: </label>
                <input type="text" class="form-control" v-model="integration.integration_access_token">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">refresh_token: </label>
                <input type="text" class="form-control" v-model="integration.integration_refresh_token">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">expires: </label>
                <input type="text" class="form-control" v-model="integration.integration_expires">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">secret: </label>
                <input type="text" class="form-control" v-model="integration.integration_secret">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">callback_url: </label>
                <input type="text" class="form-control" v-model="integration.integration_callback_url">
              </div>
              <div class="col-md-6">
                <label class="form-control-label">redirect_url: </label>
                <input type="text" class="form-control" v-model="integration.integration_redirect_url">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateIntegration()" v-if="integration.id">Editar Integração</button>
            <button type="button" class="btn btn-success" @click="addIntegration()" v-else>Cadastrar Integração</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir Integração</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body" >
                      Deseja excluir o integração?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteIntegration()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'IndexIntegrationsPage',

  components: {
    LoadingComponent
  },

  computed: mapState({
    integrations: state => state.integrations.data,
    loading: state => state.integrations.loading
  }),

  data: function () {
    return {
      integration: {
        integration: {}
      }
    }
  },

  methods: {

    ...mapActions('integrations', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    addIntegration: function () {
      this.store(this.integration)
      $('#CreateModal').modal('hide')
    },

    updateIntegration: function () {
      this.update({ id: this.integration.id, data: this.integration })
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.integration = {
        integration: {}
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (integration) {
      this.integration = integration
      $('#CreateModal').modal()
    },

    openDeleteModal: function (integration) {
      this.integration = integration
      $('#openDeleteModal').modal()
    },

    deleteIntegration: function () {
      this.destroy(this.integration.id)
      $('#openDeleteModal').modal('hide')
    }

  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
