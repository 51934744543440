<template>
  <div class="home">
    <!-- <div class="row">
      <div class="col-md-7">
        <div class="card bg-dark p-4">
          <LineChartComponent v-if="reports.orders_daily" :chart-data="reports.orders_daily" :options="options"></LineChartComponent>
        </div>
      </div>
      <div v-if="reports.financial" class="col-md-5">
        <div class="card bg-dark p-4">
          <p class="text-muted">Valor Vendido: {{ reports.financial.sold | money }}</p>
          <p class="text-muted">Valor Autorizado: {{ reports.financial.authorized | money }}</p>
          <p class="text-muted">Aguardando Pagamento: {{ reports.financial.waiting | money }}</p>
          <p class="text-muted">Valor Recusado: {{ reports.financial.refused | money }}</p>
          <p class="text-muted">Valor Cancelado: {{ reports.financial.cancelled | money }}</p>
          <p class="text-muted">Ticket Médio: {{ reports.financial.average | money }}</p>
        </div>
      </div>
    </div>
    <DoughnutChartComponent v-if="reports.regions" :chart-data="reports.regions"></DoughnutChartComponent> -->
    <div class="row">
      <div class="col-md-3 mb-4">
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor vendido</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.sold | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+2.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor autorizado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.authorized | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+0.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Aguardando pagamento</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.waiting | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-danger">-1.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor recusado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.refused | money }}</p>
            <!-- <p class="fs-16 fw-600 mb-0 c-green">+2.5%
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9965 8.58307e-06C5.81079 -0.00090313 5.62477 0.0751123 5.49117 0.228018L0.831152 5.56135C0.588893 5.83861 0.617269 6.25977 0.894531 6.50203C1.17179 6.74429 1.59295 6.71591 1.83521 6.43865L5.33318 2.43526L5.33318 11.3333C5.33318 11.7015 5.63166 12 5.99985 12C6.36804 12 6.66651 11.7015 6.66651 11.3333L6.66651 2.44665L10.1651 6.43936C10.4078 6.71628 10.8289 6.74406 11.1059 6.50141C11.3828 6.25876 11.4106 5.83756 11.1679 5.56064L6.55155 0.292299C6.43161 0.11588 6.22926 0 5.99985 0C5.99873 0 5.99762 2.86102e-06 5.9965 8.58307e-06Z" fill="#3DD598"/>
              </svg>
            </p> -->
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Total de pedidos</p>
          <LineChartComponent v-if="reports.orders_daily" :chart-data="reports.orders_daily" :options="options"></LineChartComponent>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-4">
          <p class="fs-16 fw-600 mb-0 pb-3">Valor cancelado</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.cancelled | money }}</p>
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
        <div class="card">
          <p class="fs-16 fw-600 mb-0 pb-3">Ticket médio</p>
          <div class="d-flex align-items-center">
            <p class="fs-28 fw-600 mb-0 mr-3">{{ reports.financial.average | money }}</p>
          </div>
          <p class="fs-14 fw-400 mb-0 c-grey">Comparação com o ano anterior</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
// import OrderComponent from '../components/layout/BarChart.vue'
// import DoughnutChartComponent from './chart/DoughnutChartComponent'
import LineChartComponent from './chart/LineChartComponent'

export default {
  name: 'Home',
  components: {
    // DoughnutChartComponent,
    LineChartComponent
    // OrderComponent
  },

  computed: mapState({
    reports: state => state.reports.data
  }),

  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },

  methods: {
    ...mapActions('reports', [
      'index'
    ]),

    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  },

  mounted () {
    this.index(['orders-daily', 'orders-financial'])
  }

}
</script>
