<template>
  <div class="coupons">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-3">
            <h1>Cupons</h1>
          </div>
          <div class="col-md-4 pt-2">
            <div class="input-search">
                <i class="fas fa-search input-icon"></i>
                <input type="text" v-model="search" @change="searchWithPaginate()" placeholder="Buscar cupom" class="form-control-input">
            </div>
          </div>
          <div class="col-md-5 h-10 d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-success" data-toggle="modal" @click="openCreateModal()">
              Novo Cupom
            </button>
            <button class="btn btn-outline-warning ml-2" data-toggle="modal" @click="openExcelModal()">
              Extrair Relatório
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th></th>
                <th>#</th>
                <th>Descrição</th>
                <th>Preço de Desconto</th>
                <th>Data de Início</th>
                <th>Data do Término</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coupon in coupons.data" :key="coupon.id">
                <td class="align-middle body-table text-center">
                  <i v-if="coupon.status == 0" :class="['fas', 'fa-circle', 'text-danger']"></i>
                  <i v-else :class="['fas', 'fa-circle', 'text-success']"></i>
                </td>
                <td class="align-middle body-table">{{ coupon.id }}</td>
                <th class="align-middle body-table">{{ coupon.description }}</th>
                <td v-if="coupon.type == 1" class="align-middle body-table">{{ coupon.reduction_amount | money }}</td>
                <td v-else class="align-middle body-table">{{ coupon.reduction_amount }}%</td>
                <td class="align-middle body-table">{{ coupon.start_date | date }}</td>
                <td class="align-middle body-table">{{ coupon.end_date | date}}</td>
                <td class="align-middle body-table">
                  <button class="btn mr-2" data-toggle="modal" @click="openUpdateModal(coupon)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn" data-toggle="modal" @click="openDeleteModal(coupon)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !coupons.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (coupon.id ? 'Editar Cupom' : 'Cadastrar Cupom')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <small aria-hidden="true">&times;</small>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-md-8">
                    <label class="form-control-label">Descrição</label>
                    <input type="text" class="form-control" v-model="coupon.description">
                    <small v-if="errors.description" class="text-danger">{{errors.description}}</small>
                  </div>
                  <div class="col-md-4">
                    <label class="form-control-label">Status</label>
                    <select class="form-control" v-model="coupon.status">
                      <option :value="null">-Selecione</option>
                      <option :value="1">Ativo</option>
                      <option :value="0">Inativo</option>
                    </select>
                    <small v-if="errors.status" class="text-danger">{{errors.status}}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="form-control-label">Tipo de redução:</label>
                    <select name="select-type" id="type-reduction" class="form-control form-control-alternative" v-model="coupon.type" required>
                      <option :value="null">-Selecione-</option>
                      <option :value="0">Porcentagem</option>
                      <option :value="1">Dinheiro</option>
                    </select>
                    <small v-if="errors.type" class="text-danger">{{errors.type}}</small>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Valor Desconto</label>
                    <money v-bind="coupon.type === 0 ? percentage : vmoney" type="text" id="height" v-model="coupon.reduction_amount" class="form-control" aria-label="height" aria-describedby="height-addon"></money>
                    <small v-if="errors.reduction_amount" class="text-danger">{{errors.reduction_amount}}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="form-control-label">Data Inicial</label>
                    <input type="date" class="form-control" v-model="coupon.start_date">
                    <small v-if="errors.start_date" class="text-danger">{{errors.start_date}}</small>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Data Final</label>
                    <input type="date" class="form-control" v-model="coupon.end_date">
                    <small v-if="errors.end_date" class="text-danger">{{errors.end_date}}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="form-control-label">Uso máximo</label>
                    <input type="number" class="form-control form-control-alternative" v-model="coupon.max_uses" required>
                    <small v-if="errors.max_uses" class="text-danger">{{errors.max_uses}}</small>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Limite por Usuário</label>
                    <input type="number" class="form-control" v-model="coupon.limit_per_user">
                    <small v-if="errors.limit_per_user" class="text-danger">{{errors.limit_per_user}}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="form-control-label">Primeira Compra?</label>
                    <select class="form-control" v-model="coupon.first_buy_only">
                      <option :value="null">-Selecione</option>
                      <option :value="1">Sim</option>
                      <option :value="0">Não</option>
                    </select>
                    <small v-if="errors.first_buy_only" class="text-danger">{{errors.first_buy_only}}</small>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Qtd. mínima de produtos</label>
                    <input type="number" class="form-control" v-model="coupon.min_product_quantity">
                    <small v-if="errors.min_product_quantity" class="text-danger">{{errors.min_product_quantity}}</small>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <label class="form-control-label">Valor Mínimo</label>
                    <money v-bind="vmoney" type="text" id="min_value" v-model="coupon.min_value" class="form-control" aria-label="min_value" aria-describedby="height-addon"></money>
                    <small v-if="errors.min_value" class="text-danger">{{errors.min_value}}</small>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Alvo da redução</label>
                    <select class="form-control" v-model="coupon.target">
                      <option :value="null">-Selecione</option>
                      <option :value="0">Preço</option>
                      <option :value="1">Frete</option>
                    </select>
                    <small v-if="errors.target" class="text-danger">{{errors.target}}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="validateInputs(coupon.id, pagination.current_page)" v-if="coupon.id">Editar Cupom</button>
            <button type="button" class="btn btn-success" @click="validateInputs()" v-else>Cadastrar Cupom</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title text-white" id="exampleModalLabel">Excluir Cupom</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <small aria-hidden="true">&times;</small>
                      </button>
                  </div>
                  <div class="modal-body" >
                      <p class="text-white py-0 my-0">
                        Deseja excluir o cupom?
                      </p>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteCoupon()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>

    <!-- EXCEL MODAL -->
      <div class="modal fade mt-5" id="openExcelModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog " role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Selecione o intervalo de data</h5>
                      <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                      <small aria-hidden="true">&times;</small>
                      </button>
                  </div>
                  <div class="modal-body" >
                    <div class="row">
                      <div class="col">
                        <label for="from" class="form-control-label">De:</label>
                        <input type="date" id="from" v-model="from" @change="setCouponExcelParams" class="form-control">
                      </div>
                      <div class="col">
                        <label for="to" class="form-control-label text-white">Até</label>
                        <input type="date" id="to" v-model="to" @change="setCouponExcelParams" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Fechar</button>
                      <button class="btn btn-warning" v-if="download.length == 0" :disabled="load" @click="exportExcel()">Carregar Arquivos</button>
                      <JsonExcel
                        v-else
                        class="btn btn-warning"
                        :data="download"
                        type="xls"
                        worksheet="Download"
                        name="relatório cupons"
                        data-dismiss="modal"
                      />
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'
import JsonExcel from 'vue-json-excel'
export default {

  name: 'IndexCouponsPage',

  components: {
    LoadingComponent,
    PaginateComponent,
    JsonExcel
  },

  computed: mapState({
    coupons: state => state.coupons.data,
    loading: state => state.coupons.loading,
    pagination: state => state.coupons.pagination,
    download: state => state.coupons.download
  }),

  data: function () {
    return {
      coupon: {
        description: null,
        type: null,
        reduction_amount: 0,
        start_date: null,
        end_date: null,
        max_uses: null,
        limit_per_user: null,
        first_buy_only: null,
        min_value: 0,
        min_product_quantity: null,
        target: null,
        status: null
      },
      current_page: 1,
      destroy_coupon: null,
      from: null,
      to: null,
      load: false,
      search: '',
      vmoney: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        prefix: 'R$ ',
        masked: false
      },
      percentage: {
        decimal: ',',
        thousands: '',
        precision: 2,
        prefix: '% '
      },
      errors: {
        description: null,
        type: null,
        reduction_amount: null,
        start_date: null,
        end_date: null,
        max_uses: null,
        limit_per_user: null,
        first_buy_only: null,
        min_value: null,
        min_product_quantity: null,
        target: null,
        status: null
      }
    }
  },

  methods: {

    ...mapActions('coupons', [
      'getCoupons',
      'store',
      'update',
      'destroy',
      'excel',
      'setCouponExcelParams'
    ]),

    searchWithPaginate (page) {
      this.getCoupons({ page: page, search: this.search })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    openUpdateModal: function (coupon) {
      this.coupon = coupon
      this.errors = {
        description: null,
        type: null,
        reduction_amount: null,
        start_date: null,
        end_date: null,
        max_uses: null,
        limit_per_user: null,
        first_buy_only: null,
        min_value: null,
        min_product_quantity: null,
        target: null,
        status: null
      }
      $('#CreateModal').modal()
    },

    openDeleteModal: function (coupon) {
      this.destroy_coupon = coupon
      $('#openDeleteModal').modal()
    },

    openExcelModal: function () {
      $('#openExcelModal').modal('show')
    },

    openCreateModal: function () {
      this.coupon = {
        description: null,
        type: null,
        reduction_amount: 0,
        start_date: null,
        end_date: null,
        max_uses: null,
        limit_per_user: null,
        first_buy_only: null,
        min_value: 0,
        min_product_quantity: null,
        target: null,
        status: null
      }
      this.errors = {
        description: null,
        type: null,
        reduction_amount: null,
        start_date: null,
        end_date: null,
        max_uses: null,
        limit_per_user: null,
        first_buy_only: null,
        min_value: null,
        min_product_quantity: null,
        target: null,
        status: null
      }
      $('#CreateModal').modal()
    },

    addCoupon: function () {
      this.store(this.coupon)
      $('#CreateModal').modal('hide')
    },

    updateCoupon: function () {
      this.update({ id: this.coupon.id, data: this.coupon, pagination: this.pagination.current_page })
      $('#CreateModal').modal('hide')
    },

    deleteCoupon: function () {
      console.log(this.destroy_coupon.id)
      this.destroy(this.destroy_coupon.id)
      $('#openDeleteModal').modal('hide')
    },

    exportExcel: function () {
      this.load = true
      this.excel({ from: this.from, to: this.to })
      this.load = false
    },

    validateInputs: function (id = null, page) {
      let error = 0
      if (this.coupon.description === null || this.coupon.description === '') {
        error = 1
        this.errors.description = 'Este campo é obrigatório!'
      }
      if (this.coupon.type === null) {
        error = 1
        this.errors.type = 'Este campo é obrigatório!'
      }
      if (this.coupon.start_date === null) {
        error = 1
        this.errors.start_date = 'Este campo é obrigatório!'
      }
      if (this.coupon.end_date === null) {
        error = 1
        this.errors.end_date = 'Este campo é obrigatório!'
      }
      if (this.coupon.limit_per_user === null || this.coupon.limit_per_user === '') {
        error = 1
        this.errors.limit_per_user = 'Este campo é obrigatório!'
      }
      if (this.coupon.status === null) {
        error = 1
        this.errors.status = 'Este campo é obrigatório!'
      }
      if (this.coupon.min_value === null || this.coupon.min_value === '') {
        error = 1
        this.errors.min_value = 'Este campo é obrigatório!'
      }
      if (this.coupon.first_buy_only === null) {
        error = 1
        this.errors.first_buy_only = 'Este campo é obrigatório!'
      }
      if (this.coupon.min_product_quantity === null || this.coupon.min_product_quantity === '') {
        error = 1
        this.errors.min_product_quantity = 'Este campo é obrigatório!'
      }
      if (this.coupon.target === null) {
        error = 1
        this.errors.target = 'Este campo é obrigatório!'
      }
      if (this.coupon.max_uses === null || this.coupon.max_uses === '') {
        error = 1
        this.errors.max_uses = 'Este campo é obrigatório!'
      }
      if (this.coupon.reduction_amount === 0) {
        error = 1
        this.errors.reduction_amount = 'Este campo é obrigatório!'
      }
      if (error === 0) {
        if (id) {
          this.updateCoupon()
        } else {
          this.addCoupon()
        }
      }
    }
  },

  mounted () {
    this.searchWithPaginate(1)
  }

}

</script>
