<template>
  <form @submit.prevent="save()">
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class=" mb-0">Informações principais</h6>
      </div>
      <div class="card-body pb-3">
        <div class="row">
          <div class="col-md-3 pb-3">
              <label for="status" class="form-control-label">Tipo de Documento: <span class="text-danger">*</span></label>
              <select id="status" class="form-control slt-opt" v-model="data.tax_document_type">
                  <option :value="null">Selecione</option>
                  <option :value="'CNPJ'">CNPJ</option>
                  <option :value="'CPF'">CPF</option>
              </select>
              <span class="text-danger mt-1" v-if="errors.status">Este campo é obrigatório</span>
          </div>
          <div class="col-md-3 pb-3">
            <label for="tax_document_number" class="form-control-label">Documento: <span class="text-danger">*</span></label>
            <input v-model="data.tax_document_number" class="form-control" type="text" placeholder="Digite aqui.." id="tax_document_number">
            <span class="text-danger mt-1" v-if="errors.tax_document_number">Este campo é obrigatório</span>
          </div>
          <div class="col-md-6 pb-3">
            <label for="name" class="form-control-label">Nome: <span class="text-danger">*</span></label>
            <input v-model="data.name" class="form-control" type="text" placeholder="Digite aqui.." id="name">
            <span class="text-danger mt-1" v-if="errors.name">Este campo é obrigatório</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Endereço</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-2 pb-3">
              <label for="zipcode" class="form-control-label">CEP:</label>
              <input type="text" v-model="data.zipcode" id="zipcode" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-6 pb-3">
              <label for="street" class="form-control-label">Endereço:</label>
              <input type="text" id="street" v-model="data.street" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="number" class="form-control-label">Nº:</label>
              <input type="text" id="number" v-model="data.number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2">
              <label for="complement" class="form-control-label">Comp.:</label>
              <input type="text" id="complement" v-model="data.complement" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-5 pb-3">
              <label for="district" class="form-control-label">Bairro:</label>
              <input type="text" v-model="data.district" id="district" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-5 pb-3">
              <label for="city" class="form-control-label">Cidade:</label>
              <input type="text" id="city" v-model="data.city" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="state" class="form-control-label">UF:</label>
              <input type="text" id="state" v-model="data.state" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Usuários</h6>
          </div>
          <div class="col-md text-right">
            <button class="btn btn-primary" type="button" @click="data.users.push({name: null, email: null})">+</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-for="(user, i) in data.users" :key="'user'+i">
          <div class="col-md-6 pb-3">
              <label for="user_name" class="form-control-label">Nome:</label>
              <input type="text" id="user_name" v-model="user.name" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-6 pb-3">
              <label for="user_email" class="form-control-label">E-mail:</label>
              <input type="text" id="user_email" v-model="user.email" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Regras de Split</h6>
          </div>
          <div class="col-md text-right">
            <button class="btn btn-primary" type="button" @click="data.rules.push({product_id: null, type: null, value: 0})">+</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-for="(split_rule, i) in data.rules" :key="'split_rule'+i">
          <div class="col-md-6 pb-3">
            <label for="split_product_id" class="form-control-label">Produto:</label>
            <select id="status" class="form-control slt-opt" v-model="split_rule.product_id">
                <option :value="null">Todos</option>
                <option v-for="product in products" :key="product.id" :value="product.id">{{ product.title }}</option>
            </select>
          </div>
          <div class="col-md-3 pb-3">
            <label for="split_email" class="form-control-label">Tipo:</label>
            <select id="status" class="form-control slt-opt" v-model="split_rule.type">
                <option :value="null">Selecione</option>
                <option :value="0">Porcentagem (%)</option>
                <option :value="1">Valor Fixo (R$)</option>
            </select>
          </div>
          <div class="col-md-3">
            <div class="input-group">
              <label for="split_rule_value" class="form-control-label w-100">Valor:</label>
              <div class="input-group-prepend" v-if="split_rule.type">
                <span class="input-group-text" id="split_rule_value-addon">{{ split_rule.type == 'percentage' ? '%' : 'R$'}}</span>
              </div>
              <money class="form-control weight" id="weight" v-model="split_rule.value" v-bind="money"></money>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <div class="row align-items-center">
          <div class="col-md">
            <h6 class="mb-0">Dados Bancários</h6>
          </div>
          <div class="col-md text-right">
            <button class="btn btn-primary" type="button" @click="data.banks.push({
              holder_name: data.name,
              holder_document: data.tax_document_number,
              type: null,
              param_bank_id: null,
              agency_number: null,
              agency_digit_number: null,
              account_number: null,
              account_digit_number: null
            })">+</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-for="(bank_account, i) in data.banks" :key="'user'+i">
          <div class="col-md-4 pb-3">
            <label for="bank_acount_bank_id" class="form-control-label">Banco:</label>
            <select id="bank_acount_bank_id" class="form-control slt-opt" v-model="bank_account.param_bank_id">
                <option :value="null">Selecione</option>
                <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{ bank.code }} - {{ bank.name }}</option>
            </select>
          </div>
          <div class="col-md-4 pb-3">
              <label for="bank_account_holder_name" class="form-control-label">Titular:</label>
              <input type="text" id="bank_account_holder_name" v-model="bank_account.holder_name" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
              <label for="bank_account_holder_document" class="form-control-label">Documento:</label>
              <input type="text" id="bank_account_holder_document" v-model="bank_account.holder_document" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_agency_number" class="form-control-label">Agência:</label>
              <input type="text" id="bank_account_agency_number" v-model="bank_account.agency_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_agency_digit_number" class="form-control-label">Dígito:</label>
              <input type="text" id="bank_account_agency_digit_number" v-model="bank_account.agency_digit_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_account_number" class="form-control-label">Conta:</label>
              <input type="text" id="bank_account_account_number" v-model="bank_account.account_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-2 pb-3">
              <label for="bank_account_account_digit_number" class="form-control-label">Dígito:</label>
              <input type="text" id="bank_account_account_digit_number" v-model="bank_account.account_digit_number" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-4 pb-3">
            <label for="bank_acount_bank_id" class="form-control-label">Banco:</label>
            <select id="bank_acount_bank_id" class="form-control slt-opt" v-model="bank_account.type">
                <option :value="null">Selecione</option>
                <option :value="'checking'">Corrente</option>
                <option :value="'saving'">Poupança</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success mt-3">
      Salvar Alterações
    </button>
  </form>

</template>

<script>
import { mapActions, mapState } from 'vuex'
import 'vue-advanced-cropper/dist/style.css'
export default {

  props: ['_supplier'],

  watch: {
    _supplier () {
      this.data = {
        ...this.data,
        ...this._supplier
      }
    }
  },

  data () {
    return {
      data: {
        banks: [],
        rules: [],
        users: [],
        tax_document_type: null
      },
      errors: {},
      img: null,
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      measure: {
        decimal: ',',
        thousands: '',
        precision: 2
      }
    }
  },

  computed: mapState({
    products: state => state.params.data.products,
    banks: state => state.params.data.banks,
    loading: state => state.params.loading
  }),

  methods: {

    ...mapActions('params', [
      'getProducts',
      'getBanks'
    ]),

    pixelsRestriction ({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
      this.errorImage = null

      // se a imagem for menor que 200px
      if (imageHeight < 200 || imageWidth < 200) {
        this.errorImage = 'Essa imagem é muito pequena. Para melhor visualização do produto, opte por imagens com dimensões superiores a 600x600 px'
        return false

      // se a imagem for maior que 200px e menor que 600px
      } else if (imageHeight < 600 || imageWidth < 600) {
        this.errorImage = 'Dica: Para melhor visualização do produto, opte por imagens com dimensões superiores a 600px x 600px'
        return {
          minWidth: minWidth,
          minHeight: minHeight,
          maxWidth: Math.min(imageWidth, maxWidth),
          maxHeight: Math.min(imageHeight, maxHeight)
        }
      }
    },

    uploadImage (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.img = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    crop () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.data.files.push({
        id: Date.now(),
        link: canvas.toDataURL()
      })
      this.img = null
    },

    save () {
      if (this.data.id) {
        this.$emit('update', { id: this.$route.params.id, data: this.data })
      } else {
        this.$emit('store', this.data)
      }
    },

    deleteImageInArray (index) {
      this.data.images.splice(index, 1)
    },

    changeCategory (response) {
      if (response) {
        this.data.category_id = response.category_id
        this.data.categories = response.categories_title
      }
    },

    addCoupon (coupon) {
      const found = this.data.coupons.find(dataCoupon => {
        return dataCoupon.id === coupon.id
      })
      if (!found) {
        this.data.coupons.push(coupon)
      }
    },

    addCategory (category) {
      const found = this.data.categories.find(dataCategory => {
        return dataCategory.id === category.id
      })
      if (!found) {
        this.data.categories.push(category)
      }
    }

  },

  mounted () {
    this.data = {
      ...this._supplier
    }
    this.getProducts()
    this.getBanks()
  }

}
</script>

<style>

  /* .products .form-control-label{
    color: #fff !important;
  } */

</style>
