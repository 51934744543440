<template>
    <div class="card mb-3 h-100">
        <div class="card-left">
          <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 1H1V14H16V1Z" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 6H20L23 9V14H16V6Z" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.5 19C6.88071 19 8 17.8807 8 16.5C8 15.1193 6.88071 14 5.5 14C4.11929 14 3 15.1193 3 16.5C3 17.8807 4.11929 19 5.5 19Z" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.5 19C19.8807 19 21 17.8807 21 16.5C21 15.1193 19.8807 14 18.5 14C17.1193 14 16 15.1193 16 16.5C16 17.8807 17.1193 19 18.5 19Z" stroke="#0062FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="card-right">
            <Shipping :shipping_status="shippings"/>
            <p>{{shippings[0].service_code}} - {{ shippings[0].service_description }} </p>
            <p v-if="shippings[0].deadline">{{ shippings[0].deadline }}</p>
            <p>Valor: {{ shippings[0].amount | money }}</p>
            <p v-if="shippings[0].tracking_code">Código de Rastreio: {{ shippings[0].tracking_code }}</p>
            <button v-if="shippings[0].status === 1 || shippings[0].status === 4 || shippings[0].status === 5 || shippings[0].status === 9" type="button" class="btn btn-success btn-sm btn-adjustment" data-target="#shippingCode" data-toggle="modal"><i class="fas fa-sync-alt mr-2"></i> Atualizar</button>
            <p v-if="shippings[0].status === 1 || shippings[0].status === 4" class="mb-0">
              <button type="button" class="btn mt-2 btn-success btn-adjustment" data-target="#shippingCodeChangeStatus" data-toggle="modal"><i class="fas fa-boxes"></i></button>
            </p>
            <!-- <p>Obs.: {{ shippings[0].comments }}</p> -->
        </div>
        <!-- Modal do Código de Rastreio -->
        <div class="modal mt-5" id="shippingCode" tabindex="-1" role="dialog">
          <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row py-3">
                  <div v-if="shippings[0].status === 1" class="col">
                    <label for="" class="control-label">Código de Rastreio</label>
                    <input type="text" placeholder="Ex.: 0X1Y2Z" v-model="data.shipping_code" class="form-control">
                    <span v-if="error" class="text-danger">{{ error }}</span>
                  </div>
                  <div v-if="shippings[0].status === 4" class="col">
                    <label for="" class="control-label">Pedido em Rota?</label>
                  </div>
                  <div v-if="shippings[0].status === 5" class="col">
                    <label for="" class="control-label">Pedido Entregue?</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="button" class="btn btn-success w-100" @click="save()">Atualizar Pedido</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal que atualiza o status para 'Em Separação -->
        <div class="modal mt-5" id="shippingCodeChangeStatus" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row py-3">
                  <div class="col-12">
                    <label for="" class="control-label">Deseja alterar o status de entrega do pedido para '<b>Em Separação</b>'?</label>
                  </div>
                  <div class="col-12">
                    <span class="text-danger fs-1"><b>Obs: Não será enviado nenhum e-mail para o cliente</b></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="button" class="btn btn-success w-100" @click="changeStatus()">Atualizar status</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import ShippingStatusComponent from '../components/ShippingStatusComponent.vue'
import { mapActions, mapState } from 'vuex'

export default {

  components: {
    Shipping: ShippingStatusComponent
  },

  computed: mapState({
    error: state => state.shippings.error,
    data: state => state.shippings.data
  }),

  props: ['shippings', 'client'],

  methods: {

    ...mapActions('shippings', [
      'store',
      'edit'
    ]),

    save () {
      let status

      if (this.shippings[0].status === 1) {
        status = 4
      } else if (this.shippings[0].status === 4) {
        status = 5
        this.data.shipping_code = null
      } else if (this.shippings[0].status === 5) {
        status = 6
        this.data.shipping_code = null
      }

      this.store({
        id: this.client.id,
        status: status,
        shipping_code: this.data.shipping_code
      })
    },

    changeStatus () {
      this.edit({
        id: this.client.id,
        shipping_id: this.shippings[0].id
      })
    }
  }
}
</script>

<style scoped>
    .my-card-title {
        font-size: 23px !important;
    }
    .btn-adjustment {
      font-size: 11px !important;
      padding: 4px 12px !important;
    }
</style>
