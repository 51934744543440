import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import moment from 'moment'
import Toasted from 'vue-toasted'
import './assets/sass/main.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import money from 'v-money'

import VueTheMask from 'vue-the-mask'
require('bootstrap')

window.$ = window.jQuery = require('jquery')

Vue.config.productionTip = false
Vue.use(moment)
Vue.use(VueTheMask)
Vue.use(money, { precision: 4 })

const ToastedPosition = {
  position: 'bottom-right',
  duration: 3000
}

Vue.use(Toasted, ToastedPosition)

require('./helpers/filters.js')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

$(document).ready(function () {
  $('#sidebarCollapse').on('click', function () {
    $('#sidebar').toggleClass('active')
    $('#content-dash').toggleClass('active')
  })
})
