<template>
  <div class="suppliers">
    <div class="row">
      <div class="col">
        <h1>Adicionar Fornecedor</h1>
      </div>
    </div>
    <hr>
    <FormSuppliers :_supplier="supplier" @store="store" />
    <LoadingComponent :loading="loading" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import FormSuppliers from './FormSuppliers'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'CreateSuppliersPage',

  components: {
    LoadingComponent,
    FormSuppliers
  },

  data () {
    return {
      supplier: {
        banks: [],
        rules: [],
        users: [],
        tax_document_type: null
      }
    }
  },

  watch: {
    details () {
      this.supplier = {
        ...this.details
      }
    }
  },

  computed: mapState({
    details: state => state.suppliers.details,
    loading: state => state.suppliers.loading
  }),

  methods: {

    ...mapActions('suppliers', [
      'store'
    ])

  }

}

</script>
