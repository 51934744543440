<template>
  <div class="products">
    <div class="row">
      <div class="col">
        <h1>Adicionar Produto</h1>
      </div>
    </div>
    <hr>
    <FormProducts :_product="product" @store="store" />
    <LoadingComponent :loading="loading" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import FormProducts from './FormProducts'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'ShowProductsPage',

  components: {
    LoadingComponent,
    FormProducts
  },

  data () {
    return {
      product: {}
    }
  },

  watch: {
    details () {
      this.product = {
        ...this.details
      }
    }
  },

  computed: mapState({
    details: state => state.products.details,
    loading: state => state.products.loading
  }),

  methods: {

    ...mapActions('products', [
      'store'
    ])

  }

}

</script>
