import { getTheme } from '../../services/preferences/preferences'

export const preferences = {

  namespaced: 'preferences',

  state: {
    data: {},
    loading: false,
    error: null
  },

  actions: {

    getTheme ({ state, commit }) {
      commit('setLoading', true)
      getTheme()
        .then(response => {
          commit('setTheme', response.data.theme)
          commit('setLoading', false)
        })
        .catch(error => {
          console.log(error)
          commit('setError', error.response.message)
          commit('setLoading', false)
        })
    }

  },

  mutations: {

    setTheme (state, theme) {
      document.title = theme.title
      state.data = theme
    },

    setLoading (state, status) {
      state.loading = status
    }

  },

  getters: {}

}
